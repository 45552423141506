import React from 'react';
import ReactDOM from 'react-dom';

const newDomainURL = 'https://prepago.movistar.co/recargas';

// Función para obtener los parámetros de la URL actual
const getURLParams = () => {
  const params = new URLSearchParams(window.location.search);
  return Array.from(params.entries()).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});
};

// Añadir los parámetros a la nueva URL
const addParamsToURL = (url, params) => {
  const urlObj = new URL(url);
  Object.keys(params).forEach(key => urlObj.searchParams.set(key, params[key]));
  return urlObj.toString();
};

// Redireccionar manteniendo los parámetros
if (window.location.href.split('?')[0] !== newDomainURL) {
  const currentParams = getURLParams();
  const redirectURL = addParamsToURL(newDomainURL, currentParams);
  window.location.replace(redirectURL);
} else {
  import('./App').then(({ default: App }) => {
    ReactDOM.render(<App />, document.getElementById('root'));
  });
}
